import { Button, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Stage, Layer, Image, Text } from 'react-konva';
import useImage from 'use-image';
import axios from 'axios';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import type { UploadChangeParam } from 'antd/es/upload';
// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper and modules styles
import 'swiper/css';

import './index.css';

const ImageCM: React.FC<{ url: string }> = ({ url }) => {
    const [img] = useImage(url, 'anonymous');
    const radio = 2;

    return (
        <Image width={900 * radio} height={1200 * radio} image={img} />
    );
}
const DiyImageCM: React.FC<{ url: string, x: number, y: number, w: number, h: number }> = ({ url, x, y, w, h }) => {
    const [img] = useImage(url);
    const radio = 2;
    return (
        <Image x={x * radio} y={y * radio} width={w * radio} height={h * radio} image={img} />
    );
}


const One: React.FC<{ onGenerate: any }> = ({ onGenerate }) => {
    const { TextArea } = Input;
    const [speak, setSpeak] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [rand, setRand] = useState<number>(1)
    const [imageUrl, setImageUrl] = useState<string>('')
    const [uploadImgs, setUploadImgs] = useState<string[]>([])
    const radio = 2;
    const cndUrl = "https://cdn.jinzhongtao.com/wish/"
    const stageRef = React.useRef<any>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([])

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSpeak(e.target.value)
    };

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        console.log(info);

        getBase64(info.file.originFileObj as RcFile, url => {
            addImage(url);
        });
    };
    const addImage = (image: string) => {
        if (uploadImgs.length >= arrow[rand - 1]) {
            return
        }
        setUploadImgs(prevImages => [...prevImages, image]);
    };
    const removeImage = (imageToRemove: string) => {
        setUploadImgs(prevImages => prevImages.filter(image => image !== imageToRemove));
    };
    const options = [1, 2, 3, 4, 5, 6]
    const arrow = [1, 1, 1, 1, 4, 4]
    const zIndex = [1, 1, 1, -1, -1, -1]
    const imgSize = [{
        h: 416,
        w: 557
    }, {
        h: 477,
        w: 847
    }, {
        h: 422,
        w: 418
    }, {
        h: 493,
        w: 670
    }, {
        h: 270,
        w: 410
    }, {
        h: 270,
        w: 410
    } ]

    const mp = [
        [
            {
                x: 143,
                y: 251,
                h: 416,
                w: 557
            }],
        [
            {
                x: 27,
                y: 263,
                h: 477,
                w: 847
            }],
        [
            {
                x: 35,
                y: 271,
                h: 422,
                w: 418
            }],
        [
            {
                x: 115,
                y: 548,
                h: 493,
                w: 670
            }],
        [
            {
                x: 462,
                y: 135,
                h: 270,
                w: 410
            },
            {
                x: 30,
                y: 375,
                h: 270,
                w: 410
            },
            {
                x: 466,
                y: 620,
                h: 270,
                w: 410
            },
            {
                x: 30,
                y: 865,
                h: 270,
                w: 410
            }],
        [
            {
                x: 462,
                y: 135,
                h: 270,
                w: 410
            },
            {
                x: 30,
                y: 370,
                h: 270,
                w: 410
            },
            {
                x: 466,
                y: 617,
                h: 270,
                w: 410
            },
            {
                x: 30,
                y: 863,
                h: 270,
                w: 410
            }],
       
    ]
    return (
        <div className='main'>
            <div className="one" >
                {/* <div className="card" style={{
                    backgroundImage: "url("+cndUrl + rand + ".png)"
                }}>
                </div> */}

                <Swiper
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    spaceBetween={20}
                    onSlideChange={(e: any) => {
                        setRand(e.activeIndex + 1)
                        setUploadImgs([])
                    }}
                >
                    {options.map(item => (
                        <SwiperSlide className={rand == item ? 'check' : ''} ><img key={item} onClick={
                            () => {
                                setRand(item)
                            }
                        } src={cndUrl + item + ".png"} alt="" /></SwiperSlide>
                    ))}
                </Swiper>
                <div className='tip'>这个模板需要上传{arrow[rand - 1]}张图片哦！</div>
                <div className='template'>
                    {uploadImgs.map(item => (
                        <div className='item' key={item} >
                            <img className="image" src={item} alt="" />
                            <img onClick={() => { removeImage(item) }} className='icon' src={cndUrl + "d.png"} />
                        </div>
                    ))}
                </div>
                <div className="button-list">
                    <div className="button purple">
                        <ImgCrop quality={1} aspect={imgSize[rand - 1].w / imgSize[rand - 1].h} onModalOk={() => {
                            if (uploadImgs.length >= arrow[rand - 1]) {
                                message.error('图片超过限制✨')
                                return
                            }
                            message.success('图片设置成功✨')
                        }}>
                            <Upload
                                customRequest={({ file }) => {
                                    getBase64(file as RcFile, url => {
                                        addImage(url);
                                    });
                                }}
                                fileList={fileList}
                                // onChange={handleChange}
                                showUploadList={false}>
                                DIY图片
                            </Upload>
                        </ImgCrop>
                    </div>
                    <div className="button red" onClick={() => {
                        const temp = stageRef.current?.toDataURL({ quality: 1, pixelRatio: 1 / radio });
                        onGenerate(false, temp)
                    }}>开始生成</div>
                </div>

            </div>
            <Stage className='stage' width={900 * radio} height={1200 * radio} ref={stageRef} >
                {zIndex[rand - 1] < 0 && uploadImgs.map((item, index) => (
                    <Layer>
                        <DiyImageCM url={item} x={mp[rand - 1][index].x} y={mp[rand - 1][index].y} w={mp[rand - 1][index].w} h={mp[rand - 1][index].h} />
                    </Layer>
                ))}
                <Layer>
                    <ImageCM url={cndUrl + rand + ".png"} />
                </Layer>
                {zIndex[rand - 1] > 0 && uploadImgs.map((item, index) => (
                    <Layer>
                        <DiyImageCM url={item} x={mp[rand - 1][index].x} y={mp[rand - 1][index].y} w={mp[rand - 1][index].w} h={mp[rand - 1][index].h} />
                    </Layer>
                ))}
            </Stage>
        </div >
    );
}

export default One;
