import { Input, Drawer, Space, Button, Form, message } from 'antd';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './index.css';


const Two: React.FC<{ uri: any; onBack: any }> = ({ uri, onBack }) => {
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();

    const onShare = (image: string) => {
        setOpen(true)
    };
    const onClose = () => {
        setOpen(false)
    }
    const onSubmit = () => {
        try {
            const data = axios.post('https://fastadmin.t.jinzhongtao.com/api/index/index', {
                stuid: form.getFieldValue('stuid'),
                name: form.getFieldValue('name'),
                college: form.getFieldValue('college'),
                image: uri
            }).then(function (data : any) {
                if(data.data.code === 1) {
                    message.success("上传成功")
                    setOpen(false)
                } else {
                    message.error("上传失败")
                }
            })
        } catch (error) {
            
        }
    }
    return (
        <>
            <div className='two'>
                <div className="card">
                    {uri && <img className='result' src={uri} alt="" />}
                    <div className='save-tip'>长按图片可保存到本地</div>
                    <div className="btn">
                        <div className="save" onClick={() => {
                            onBack(false)
                        }}>再来一张</div><div className="share" onClick={() => {
                            onShare(uri)
                        }}>我要投稿</div></div>
                </div>
            </div>
            <Drawer
                title='“我和湖大合张影”照片征集'
                open={open}
                placement='bottom'
                onClose={onClose}
                height={450}
                extra={
                    <Space>
                        <Button shape="round" onClick={onSubmit} type="primary">
                            提交
                        </Button>
                    </Space>
                }
                className='drawer'
            >
                <div style={{marginBottom: '10px'}}>如同意参加“我和湖大合张影”活动，请在本页面登记个人信息并点击提交，活动主办方将拥有该图片的版权使用权。</div>

                <Form layout="vertical" form={form}>
                    <Form.Item
                        name="name"
                        label="姓名"
                        rules={[{ required: true, message: '请输入姓名' }]}
                    >
                        <Input
                            placeholder="请输入姓名"
                        />
                    </Form.Item>
                    <Form.Item
                        name="stuid"
                        label="学号"
                        rules={[{ required: true, message: '请输入学号' }]}
                    >
                        <Input
                            placeholder="请输入学号"
                        />
                    </Form.Item>
                    <Form.Item
                        name="college"
                        label="学院"
                        rules={[{ required: true, message: '请输入学院' }]}
                    >
                        <Input
                            placeholder="请输入学院"
                        />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
}

export default Two;
