import React, { useState } from 'react';
import One from './components/One';
import Two from './components/Two';

import './App.css'

const App: React.FC = () => {
  const [one, setOne] = useState<boolean>(true)
  const [two, setTwo] = useState<boolean>(false)
  const [three, setThree] = useState<boolean>(false)
  const [uri, setUri] = useState<any>('')
  return (
    <div className="App" >
      {one && <One onGenerate={(e: boolean, uri: any) => { setTwo(!e); setUri(uri) }}></One>}
      {two && <Two onBack={(e: boolean) => { setTwo(e) }} uri={uri}></Two>}
    </div >
  );
}

export default App;
